// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Class Export
export class NotificationManager {
	static askNotificationPermissions() {
		if (NotificationManager.isNotificationSupported()) {
			return Notification.requestPermission()
		}
	}

	static hasGrantedPermission() {
		if (NotificationManager.isNotificationSupported()) {
			return Notification.permission === 'granted'
		}
		return false
	}

	static isNotificationSupported() {
		const isSupported = 'Notification' in window
		if (!isSupported) {
			DevelopmentTools.printError('[Notifier] La Implementación de la API de Notificaciones no esta soportada por el Navegador!')
		}
		return isSupported
	}

	static showNotificationWithActions(self: any, title: string, actions?: Array<any>) {
		self.registration.showNotification(title, { actions })
	}
}