// Components
import CompanyLogo from '@/Components/Extras/CompanyLogo/template.vue'

// Constants
import { VersionTracking } from '@/Constants/Global/VersionTracking'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const LoginFooter = VueMixins(MixinResponsive).extend({
	name: 'LoginFooter',

	components: {
		CompanyLogo
	},

	computed: {
		_getBuildVersionCode: function() {
			return VersionTracking.CurrentBuild
		},

		_getBuildVersionName: function() {
			return VersionTracking.CurrentVersion
		}
	}
})

// Exports
export default LoginFooter
export type LoginFooterRef = InstanceType<typeof LoginFooter>