// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Export
const LoginHeader = VueMixins(MixinResponsive).extend({
	name: 'LoginHeader'
})

// Exports
export default LoginHeader
export type LoginHeaderRef = InstanceType<typeof LoginHeader>