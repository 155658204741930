// Classes
import { NotificationManager } from '@/Classes/Static/NotificationManager'

// Components
import LoginFooter from '@/Components/Modules/0/LoginFooter/template.vue'
import LoginForm   from '@/Components/Modules/0/LoginForm/template.vue'
import LoginHeader from '@/Components/Modules/0/LoginHeader/template.vue'

// Constants
import { Documents } from '@/Constants/Global/Documents'
import { VueRouter } from '@/Constants/Global/VueRouter'

// Dependencies
import jwtDecode from 'jwt-decode'
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'


// Component Export
export default VueMixins(MixinBase,MixinResponsive).extend({
	name: VueRouter.Global.View00.NAME,

	components: {
		LoginFooter,
		LoginHeader,
		LoginForm
	},

	data: function() {
		return {
			maxLoadTime: 20000
		}
	},

	created: function() {
		if (NotificationManager.isNotificationSupported()) {
			// La vista de Login corresponde a la primera que es cargada cuando
			// el usuario entra al sitio web, realizar aquí la validación de los
			// permisos para poder Notificar al usuario posteriormente.
			const { permission } = Notification

			// Si no se tiene explicitamente los permisos, verificar si se le solicitaron
			// previamente al Usuario.
			if (permission === 'default') {
				NotificationManager.askNotificationPermissions()
			}

			// Por lo contrario, si ya fueron negados por el Usuario, simplemente informarle
			// que se encuentran negados en caso que el decida otorgarlos en un futuro.
			else if (permission === 'denied') {
				this.showToast('Notificaciones Deshabilitadas', 'Las Notificaciones no estarán disponibles hasta que usted autorice poder recibirlas.', 'danger')
			}

			// Finalmente, si se tienen permisos para poder Notificar al Usuario.
			else {

			}
		}
	},

	mounted: function() {
		// El parametro 'successLogout' se encuentra definido unicamente
		// cuando se redirecciona a esta vista al cerrar sesión.
		if (this.$route.params.successLogout === 'true') {
			this.showToast(`Sesión Finalizada`, 'Se a cerrado la sesión correctamente!', 'success')
		}
	},

	methods: {
		onFailedLogin: function() {
			this.showToast('Error al Iniciar Sesión', 'Verifica que el Email ingresado y la Password sean correctos', 'danger')
		},

		onSuccessLogin: async function(response: any, password: string) {
			const { accessToken } = response
			const user = jwtDecode(accessToken).data[0]
			
			// Emitir el evento de Inicio de Sesión al Servidor.
			Store.getters.getStoredSocket.emit('userLoggedIn', { channel: 'web', user })

			// Fin del Proceso de Inicio de Sesión.
			Store.commit('storeCredentials', { accessToken, user })

			// Antes de redireccionar, obtener los Permisos del Usuario.
			const permissions = await Store.dispatch('fetchPermissionsRole', user._idRole)
			Store.commit('storeUserPermissions', permissions)

			// Redireccionar al CPanel correspondiente segun el Rol del Usuario.
			const isClient = user._idRole === Documents.Roles.Kreis.CLIENT
			const name = isClient ? VueRouter.Modules.View11.NAME : VueRouter.Modules.View10.NAME
			this.$router.push({ name, params: { successLogin: 'true' } })
		}
	}
})